.App {
  text-align: center;
}

.navbarHeader {
  height: 0em;
  transition: 0.5s;
  -webkit-transition: 0.5s;
}

.navbarHeader.active{
  height: 5em;
  background: #ccc !important;
  transition: 0.5s;
  -webkit-transition: 0.5s;
}

.backgroundImage{
  background-image: url(/images/vg.jpg);
  width: 130%; 
  height: 100%; 
  position: absolute;
  opacity: 0.4;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerText {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.2em;
  font-weight: 500;
  padding-right: 2em;
  padding-top: 2em;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.links {
  text-decoration: none;
  position: relative;
  padding: 10px 0;
  font-family: "Quicksand", Arial, sans-serif;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scrollDownArrow {
	position: absolute;
	left: 50%;
	bottom: 30px;
	display: block;
	text-align: center;
	font-size: 20px;
	z-index: 100;
	text-decoration: none;
	text-shadow: 0;
  width: 40px;
  height: 40px;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  z-index: 9;
  left: 50%;
  -webkit-transform: translate(-50%, 0%) rotate(45deg);
  -moz-transform: translate(-50%, 0%) rotate(45deg);
  transform: translate(-50%, 0%) rotate(45deg);
	-webkit-animation: fade_move_down 1.5s ease-out infinite;
	-moz-animation:    fade_move_down 1.5s ease-out infinite;
	animation:         fade_move_down 1.5s ease-out infinite;
}


/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
  0%   { -webkit-transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -webkit-transform:translate(0,10px) rotate(45deg); opacity: 0; }
}
@-moz-keyframes fade_move_down {
  0%   { -moz-transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -moz-transform:translate(0,10px) rotate(45deg); opacity: 0; }
}
@keyframes fade_move_down {
  0%   { transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(0,10px) rotate(45deg); opacity: 1; }
}

.SlideArrow {
  bottom: auto;
  border-bottom: 2px solid #0d0c0c;
  border-right: 2px solid #1a1818;
}